<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="合同签署人">
              <a-input v-model="queryParam['%signor']" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="合同编号">
              <a-input v-model="queryParam['%serial_num']" />
            </a-form-item>
          </a-col>
          <a-col :xs="6">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <!--      <a-button type="primary" @click="handleConfirm(true)">审核</a-button>-->
      <a-button type="danger" @click="handleConfirm(false)" :disabled="!this.selectedRowKeys.length">作废</a-button>
    </div>

    <s-table
      ref="table"
      size="default"
      rowKey="id"
      :columns="columns"
      :data="loadData"
      :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="contract_files" slot-scope="text">
        <template v-for="(f, index) in text">
          <span v-if="index > 0" :key="'k' + f.uuid"> | </span>
          <a :key="f.uuid" :href="GLOBAL.baseUrl + '/file/download?uuid=' + f.uuid">{{ f.name }} </a>
        </template>
      </span>
      <span slot="action" slot-scope="text, record, index">
        <template>
          <a @click="handleApproval(record, index)">合同审批</a>
        </template>
      </span>
    </s-table>

    <a-modal
      :visible="formVisible"
      :confirmLoading="confirmLoading"
      title="合同审批"
      :maskClosable="false"
      :width="920"
      @cancel="formVisible = false"
      class="contract-approval"
      @ok="handleSubmit"
    >
      <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
        <a-row :gutter="20">
          <a-col :span="6">
            <a-form-model-item label="合同编号">
              <a-input v-model="form.serial_num" disabled />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="合同签署人">
              <a-input v-model="form.signor" disabled />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="合同签订日期">
              <a-date-picker v-model="form.sign_date" disabled class="full-width" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="合同截止日期">
              <a-date-picker v-model="form.end_date" disabled class="full-width" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="所属部门">
              <a-select
                v-model="form.department"
                showSearch
                ref="department"
                mode="multiple"
                allowClear
                style="width: 100%"
              >
                <a-select-option v-for="o in GLOBAL.departmentMaps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="合同类型">
              <a-select :filter-option="filterOption" v-model="form.lease_type" showSearch ref="lease_type" allowClear style="width: 100%">
                <a-select-option v-for="o in contractTypeOps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="付款类型">
              <a-select :filter-option="filterOption" v-model="form.pay_type" showSearch ref="pay_type" allowClear style="width: 100%">
                <a-select-option v-for="o in businessTypeOps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="合同金额">
              <a-input v-model="form.rental" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item label="合同影印件">
          <a-upload
            multiple
            class="contract"
            :showUploadList="{ showPreviewIcon: true, showRemoveIcon: false }"
            :fileList="defaultFileList"
          ></a-upload>
        </a-form-model-item>
        <!-- 需要审批的层级与已经审批的层级相同说明已经审批完成 -->
        <template v-if="form.contract_status === 1 || form.contract_status === 5">
          <a-divider>审批意见</a-divider>
          <a-row>
            <a-col :span="6">
              <a-radio-group v-model="check">
                <a-radio-button :value="true">通过</a-radio-button>
                <a-radio-button :value="false">驳回</a-radio-button>
              </a-radio-group>
            </a-col>
            <a-col :span="18">
              <a-textarea placeholder="请输入回复内容" v-model="replayMsg" v-if="check"></a-textarea>
              <a-textarea placeholder="请输入驳回意见" v-model="rejectMsg" v-else></a-textarea>
            </a-col>
          </a-row>
        </template>
        <template v-if="form.contract_status === 5">
          <a-divider>新增审批流程</a-divider>
          <a-row :gutter="20">
            <template v-for="(p, index) in confirm_users">
              <a-col :span="6" :key="index">
                <a-form-model-item :label="confirm_users[index].name">
                  <a-select
                    v-model="confirm_users[index].confirm_users"
                    showSearch
                    mode="multiple"
                    :filter-option="filterOption"
                    :disabled="confirm_users[index].id"
                    allowClear
                    style="width: 100%"
                  >
                    <a-select-option v-for="o in confirmUserOps" :key="o.value">{{ o.name }}</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </template>
            <a-col :span="2" style="padding-top:24px" v-if="confirm_users.length > 1">
              <a-button @click="handleRemoveApprover">删除</a-button>
            </a-col>
            <a-col :span="2" style="padding-top:24px">
              <a-button type="primary" @click="handleAddApprover">添加审批人</a-button>
            </a-col>
          </a-row>
        </template>
        <a-divider>审批流程</a-divider>
        <template>
          <div>
            <a-steps progress-dot direction="vertical">
              <a-step
                :key="index"
                v-for="(p, index) in approvalDetail"
                :title="p.title"
                :status="p.status"
                :description="p.description"
              />
            </a-steps>
          </div>
        </template>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import moment from 'moment'
import { getMmsContractPage, contractConfirm, contractVerfiy, getMmsOptions } from '@/api/mms'
import { getFirstLetter } from '@/utils/util'
export default {
  components: {
    STable
  },
  data() {
    return {
      confirm_users: [],
      queryParam: {},
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 30
        },
        {
          title: '所属部门',
          dataIndex: 'department_name'
        },
        {
          title: '合同签署人',
          dataIndex: 'signor'
        },
        {
          title: '合同编号',
          dataIndex: 'serial_num'
        },
        {
          title: '签订日期',
          dataIndex: 'sign_date',
          width: 120,
          customRender: text => {
            return moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '截止日期',
          dataIndex: 'end_date',
          width: 120,
          customRender: text => {
            return moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '合同状态',
          dataIndex: 'contract_status',
          customRender: text => {
            switch (text) {
              case 0:
                return '未提交'
              case 1:
                return '审批中'
              case 2:
                return '已驳回'
              case 3:
                return '审批完成'
              case 5:
                return '已提交'
              case 4:
                return '已归档'
              case 6:
                return '已作废'
            }
          }
        },
        {
          title: '合同影印件',
          dataIndex: 'contract_files',
          scopedSlots: { customRender: 'contract_files' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      selectedRowKeys: [],
      loadData: parameter => {
        return getMmsContractPage('lease_list_doing', Object.assign(parameter, this.queryParam)).then(res => {
          return res
        })
      },
      leaseTypeOps: [],
      payTypeOps: [],
      confirmUserOps: [],
      form: {
        serial_num: '',
        signor: '',
        sign_date: null,
        end_date: null,
        department: [],
        lease_type: null,
        lease_type_name: '',
        pay_type: null,
        pay_type_name: '',
        rental: null,
        confirm_users: [],
        contract_files: [],
        current_level: 0,
        editable: false
      },
      rejectMsg: '',
      replayMsg: '',
      check: true,
      rules: {},
      defaultFileList: [],
      levelLabel: {
        0: '一级审批人',
        1: '二级审批人',
        2: '三级审批人',
        3: '四级审批人',
        4: '五级审批人',
        5: '六级审批人',
        6: '七级审批人',
        7: '八级审批人',
        8: '九级审批人',
        9: '十级审批人',
        10: '十一级审批人',
        11: '十二级审批人',
        12: '十三级审批人',
        13: '十四级审批人',
        14: '十五级审批人'
      },
      currentData: null,
      confirmUsers: [],
      confirmDtos: [],
      formVisible: false,
      confirmLoading: false,
      approvalDetail: [],
      businessTypeOps: [],
      contractTypeOps: []
    }
  },
  created() {
    this.getFormBasicData()
    getMmsOptions('meta/mms_business_type_info').then(v => {
      this.businessTypeOps = v
    })
    getMmsOptions('meta/mms_contract_type_info').then(v => {
      this.contractTypeOps = v
    })
  },
  methods: {
    moment,
    handleApproval(record) {
      this.getFormBasicData()
      this.formVisible = true
      const that = this
      this.currentData = record
      this.form = { ...record }
      this.form.department = this.form.department.map(v => {
        return parseInt(v)
      })
      this.rejectMsg = ''
      this.replayMsg = ''
      this.check = true
      if (typeof record.confirm_infos !== 'undefined') {
        const confirmInfos = []
        const confirmUsers = [{ level: 1, name: '一级审批人', confirm_users: [] }]
        record.confirm_infos.forEach(recordData => {
          let title = ''
          if (recordData.confirm_level === 0) {
            title = '合同审批人'
          } else {
            title = this.levelLabel[recordData.confirm_level - 1]
          }
          let userName = ''
          that.confirmUserOps.forEach(data => {
            if (recordData.user_id === data.value) {
              userName = data.name
            }
          })
          let status = ''
          let stepsStatus = ''
          switch (recordData.status) {
            case 0:
              status = '未审批'
              stepsStatus = 'wait'
              break
            case 1:
              status = '通过'
              stepsStatus = 'finish'
              break
            case 2:
              status = '驳回'
              stepsStatus = 'error'
              break
          }
          const mes = userName + ':' + status + ' -- 回复:' + recordData.confirm_msg
          confirmInfos.push({
            title: title,
            description: mes,
            status: stepsStatus
          })
        })
        this.approvalDetail = confirmInfos
        const confirmLevel = []
        record.confirm_infos.forEach(recordData => {
          if (recordData.confirm_level !== 0 && recordData.confirm_level !== 1) {
            confirmLevel.push(recordData.confirm_level)
          }
        })
        const uniqueIds = Array.from(new Set(confirmLevel))
        uniqueIds.forEach(recordData => {
          confirmUsers.push({
            level: recordData,
            name: this.levelLabel[recordData - 1],
            confirm_users: []
          })
        })
        record.confirm_infos.forEach(recordData => {
          confirmUsers.forEach(data => {
            if (data.level === recordData.confirm_level) {
              data.confirm_users.push(recordData.user_id)
            }
          })
        })
        this.confirm_users = confirmUsers
      }
      this.defaultFileList = this.form.contract_files.map(f => {
        return {
          uid: f.uuid,
          name: f.name,
          status: 'done',
          url: this.GLOBAL.baseUrl + '/file/download?uuid=' + f.uuid
        }
      })
      this.form.sign_date = this.form.sign_date ? moment(this.form.sign_date, 'YYYY/MM/DD') : null
      this.form.end_date = this.form.end_date ? moment(this.form.end_date, 'YYYY/MM/DD') : null
    },
    getFormBasicData() {
      if (!this.confirmUserOps || this.confirmUserOps.length === 0) {
        getMmsContractPage('confirm_all_user_list').then(v => {
          this.confirmUserOps = v
        })
      }
    },
    handleConfirm(flag) {
      this.$confirm({
        title: '确认提示',
        content: '确定要' + (flag ? '审核' : '作废'),
        onOk: () => {
          const _ids = this.selectedRowKeys.join(',')
          contractVerfiy(flag, _ids).then(v => {
            this.$notification[v.success ? 'success' : 'error']({
              message: '提示',
              description: v.success ? '操作成功.' : v.message
            })
            if (v.success) {
              this.$refs.table.refresh(true)
            }
          })
        }
      })
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.confirmLoading = true
          const data = {
            ...this.form,
            sign_date: this.currentData.sign_date,
            end_date: this.currentData.end_date,
            lease_type_name: this.$refs['lease_type'].$el.innerText,
            pay_type_name: this.$refs['pay_type'].$el.innerText,
            department_name: this.$refs['department'].$el.innerText
          }
          contractConfirm({
            lease_info: data,
            status: this.check ? 1 : 2,
            msg: this.check ? this.replayMsg : this.rejectMsg,
            confirm_users: this.confirm_users
          })
            .then(r => {
              this.confirmLoading = false
              this.$notification[r ? 'success' : 'error']({
                message: '提示',
                description: r ? '审批成功' : '审批失败'
              })
              if (r) {
                this.formVisible = false
                this.$refs.table.refresh(true)
              }
            })
            .catch(_ => {
              this.formVisible = false
              this.confirmLoading = false
            })
        } else {
          return false
        }
      })
    },
    handleAddApprover() {
      this.confirm_users.push({
        level: this.confirm_users.length + 1,
        name: this.levelLabel[this.confirm_users.length],
        confirm_users: []
      })
    },
    handleRemoveApprover() {
      this.confirm_users.splice(this.confirm_users.length - 1, 1)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>

<style>
.contract .ant-upload-list > div {
  display: inline-block;
  width: 33.3%;
}

.contract-approval .ant-select-disabled *,
.contract-approval [disabled] {
  color: rgba(0, 0, 0, 0.65);
}
</style>
